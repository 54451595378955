import JSONfn from 'json-fn'
import JSZip from 'jszip'
import { useCallback, useEffect } from 'react'
import { ActionCreators } from 'redux-undo'
import { postFile } from '../helpers/ajax.helpers'
import { S3_PREFIX, S3_URL, getImageMetadata } from '../helpers/file.helpers'
import { AssetT, getGraphicAssetsAsync } from '../store/slices/assets.slice'
import { setPreferencesAsync } from '../store/slices/editor.slice'
import { processFileFulfilled } from '../store/slices/file.slice'
import { pushTmpIds, uploadObjectsAndAnimationsAsync } from '../store/slices/objects.slice'
import { useCanvas } from './useCanvas'
import { useEditorSettings } from './useEditorSettings'
import { useObjectActions } from './useObjectActions'
import { useAppDispatch, useAppSelector } from './useRedux'
import { useTimelineActions } from './useTimelineActions'

const useEditor = (id: string | null) => {
    const { addCanvas } = useCanvas()
    const { clearScene } = useObjectActions()
    const { uploadTimeline } = useTimelineActions()
    const file = useAppSelector((state) => state.file)
    const graphic = useAppSelector((state) => state.graphic.data)
    const masterTimeline = useAppSelector((state) => state.masterTimeline.value)
    const dispatch = useAppDispatch()

    // Get <meta> of HTML with property equals to "metaName" and return its "content"
    const getMetaTag = (file: any, metaName: string): string => {
        const toNodes = (html: string) => new DOMParser().parseFromString(html, 'text/html')
        const metas = toNodes(file).getElementsByTagName('meta')

        let result: string = ''

        for (let i = 0; i < metas.length; i++) {
            // looking for meta's property, which is equals to metaName
            if (metas[i].getAttribute('property') === metaName) {
                result = metas[i].getAttribute('content') ?? ''
            }
        }
        return result
    }

    const handleZipRead = async (file: Blob) => {
        masterTimeline?.clear()
        clearScene()
        const root = await JSZip.loadAsync(file)
        let html = await root.file('index.html')?.async('string')!

        const assets = root.folder('assets')
        processFile(html, true, assets)
    }

    const getSequenceWidth = async (animations: AnimationI[], assets: JSZip) => {
        for (const animation of animations) {
            if (animation.tween.name === 'sequence') {
                for (const f of animation.tween.from) {
                    if (f.property === 'attr.src') {
                        // get sequence path
                        const path = f.value.substring(f.value.indexOf('/') + 1)
                        const blob = await assets.file(path)?.async('blob')
                        if (blob) {
                            const { width } = await getImageMetadata(blob)
                            return width
                        }
                    }
                }
            }
        }

        return 480
    }

    const processAnimations = async (animations: AnimationI[], assets: JSZip) => {
        const width = await getSequenceWidth(animations, assets)
        return await Promise.all(
            animations.map(async (animation) => {
                if (animation.tween.name === 'sequence') {
                    animation.tween.from = await Promise.all(
                        animation.tween.from.map(async (f) => {
                            if (f.property === 'attr.src') {
                                const regex = new RegExp(`assets/sequences/[a-zA-Z0-9-]+/([^/]+)`)
                                const match = regex.exec(f.value)
                                if (match) {
                                    const [name, id, ext] = match[1].split('.')
                                    let url = `${S3_URL}/${id}`
                                    const blob = await assets
                                        .file(`sequences/${animation.objectId}/${match[1]}`)
                                        ?.async('blob')
                                    if (blob) {
                                        const file = new File([blob], `${name}.${ext}`, {
                                            type: `image/${ext}`,
                                        })

                                        const fileMetadata = await postFile(file, {
                                            storage: 's3',
                                            key: `${S3_PREFIX}/${graphic.tmpId}/sequences/${animation.objectId}`,
                                            isPublic: true,
                                            thumbnailWidth: Math.round(width / 2),
                                            hasFilename: true,
                                        })
                                        url = fileMetadata.publicUrl!
                                    }
                                    return {
                                        ...f,
                                        value: url,
                                    }
                                }
                            }
                            return f
                        })
                    )
                }
                return animation
            })
        )
    }

    const processObjects = async (objects: AnySceneObjectT[], assets: JSZip, ids: string[]) => {
        return await Promise.all(
            objects.map(async (object) => {
                if (object.type === 'image') {
                    object.fill = await Promise.all(
                        object.fill.map(async (f) => {
                            if (f.property === 'backgroundImage') {
                                const regex = new RegExp(`"assets/images/([^/]+)"`)
                                const match = regex.exec(f.value)
                                if (match) {
                                    const [name, id, ext] = match[1].split('.')
                                    let url = `${S3_URL}/${id}`
                                    const blob = await assets
                                        ?.file(`images/${match[1]}`)
                                        ?.async('blob')
                                    if (blob) {
                                        const file = new File([blob], `${name}.${ext}`, {
                                            type: `image/${ext}`,
                                        })
                                        const fileMetadata = await postFile(file, {
                                            storage: 's3',
                                            key: `${S3_PREFIX}/${graphic.tmpId}/images/${object.id}`,
                                            isPublic: true,
                                            hasFilename: true,
                                        })
                                        url = fileMetadata.publicUrl!
                                    }

                                    return {
                                        ...f,
                                        value: `url("${url}")`,
                                    }
                                }
                            }
                            return f
                        })
                    )
                    ids.push(object.id)
                }
                return object
            })
        )
    }

    const { companyId } = useEditorSettings()

    const processFile = async (file: string, isImport: boolean, files?: JSZip | null) => {
        const assets: AssetT[] = JSONfn.parse(getMetaTag(file, 'assets'))
        dispatch(getGraphicAssetsAsync(assets ?? [], companyId))

        const canvas: CanvasI = JSONfn.parse(getMetaTag(file, 'canvas'))
        addCanvas(canvas)

        //set canvas resolution from canvas metaTag when id is null
        //when id !== null canvasResolution is set from graphic.canvasSize[]
        if (id === null) {
            dispatch(setPreferencesAsync('canvasResolution', [canvas.width, canvas.height]))
        }

        let objects: AnySceneObjectT[] = JSONfn.parse(getMetaTag(file, 'objects'))
        if (files) {
            const ids: string[] = []
            objects = await processObjects(objects, files, ids)
            dispatch(pushTmpIds(ids))
        }

        let animationState = getMetaTag(file, 'animations')
        let animations: AnimationI[] = []
        if (animationState.length !== 0) {
            animations = JSONfn.parse(animationState)
            if (files) {
                animations = await processAnimations(animations, files)
            }
        }
        dispatch(uploadObjectsAndAnimationsAsync(objects, animations))

        let timelineState = getMetaTag(file, 'timeline')
        if (timelineState.length !== 0) {
            const timeline: TimelineI = JSONfn.parse(timelineState)
            uploadTimeline(timeline, isImport)
        }
    }

    const handleFileRead = useCallback((file: Blob, isImport: boolean) => {
        masterTimeline?.clear()
        clearScene()
        var reader = new FileReader()

        reader.addEventListener('loadstart', () => {
            clearScene()
        })

        reader.addEventListener('load', function () {
            try {
                processFile(reader.result as string, isImport)
            } catch (e) {
                console.error(e)
            }
        })

        reader.addEventListener('loadend', () => {
            dispatch(processFileFulfilled())
            dispatch(ActionCreators.clearHistory())
        })

        reader.readAsText(file)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (file.data?.blob && !file.status) {
            handleFileRead(file.data.blob, false)
        }
    }, [file.data, handleFileRead, file.status])

    return {
        status: file.status,
        handleFileRead,
        handleZipRead,
    }
}

export default useEditor
